
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import moment from "moment";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import CoursesInMonth from "./widgets/CoursesInMonth.vue";
import JwtService from "@/core/services/JwtService";

interface Subject {
  _id: string;
  name: string;
}

interface Course {
  icon: {
    file: string;
    color: string;
  };
  _id: string;
  title: string;
  createdAt: string;
  teacher: {
    _id: string;
    fullName: string;
  };
  subject: Subject;
  classrooms: string[];
  module: string;
  status: string;
  reviewDate: string;
  reviewNote: string;
}

export default defineComponent({
  name: "teacher-courses",
  components: { CoursesInMonth },
  props: {},
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const user = store.getters.currentUser;

    const courses = ref<Course[]>([]);
    const filteredCourses = ref<Course[]>([]);

    const search = ref<string>("");
    const selectedClass = ref<string>("");

    const subjects = ref<Subject[]>([]);
    const selectedSubject = ref<string>("");
    const loading = ref(true);
    const validateStat = ref<number>(0);

    ApiService.get("/lms/course/list")
      .then(({ data }) => {
        data.forEach((course) => {
          courses.value.push({
            icon: {
              file: "media/icons/duotune/files/fil003.svg",
              color: "success",
            },
            _id: course._id,
            title: course.title,
            createdAt: course.createdAt,
            teacher: {
              fullName: `${course.teacherName.firstName} ${course.teacherName.lastName}`,
              _id: course.teacher._id,
            },
            subject: course.subject,
            classrooms: course.classrooms,
            module: course.module,
            status: course.status || "notValidated",
            reviewDate: course.reviewDate || course.updatedAt,
            reviewNote: course.reviewNote,
          });

          if (!subjects.value.find((s: Subject) => s._id == course.subject._id))
            subjects.value.push(course.subject);
        });
        filteredCourses.value = [...courses.value];
      })
      .catch((e) => console.log(e))
      .finally(() => {
        loading.value = false;
      });

    const classroomsData = ref<{ classRoom: { _id: string; name: string } }[]>(
      []
    );
    const jwtTrimester = JwtService.getTrimester();
    let trimester = "1";
    if (jwtTrimester && jwtTrimester != "0") trimester = jwtTrimester;
    ApiService.post("/teacher/classRommDetails", {
      query: {
        teacher: user._id,
        trimester: trimester,
      },
    })
      .then(({ data }) => {
        classroomsData.value = data;
      })
      .catch((e) => console.log(e));

    const filterCourses = () => {
      currentPage.value = 1;
      filteredCourses.value = courses.value.filter((course) => {
        const toSearch = search.value.toLowerCase();
        return (
          course.title.toLowerCase().includes(toSearch) &&
          (selectedClass.value == "" ||
            course.classrooms.find((id) => id == selectedClass.value)) &&
          (course.subject._id == selectedSubject.value ||
            selectedSubject.value == "") &&
          ((course.status === "validated" && validateStat.value == 1) ||
            (course.status === "notValidated" && validateStat.value == 2) ||
            (course.status === "rejected" && validateStat.value == 3) ||
            validateStat.value == 0)
        );
      });
    };

    const getClassroomCourses = (id: string) => {
      return courses.value.filter((course: Course) =>
        course.classrooms.includes(id)
      ).length;
    };

    onMounted(() => {
      setCurrentPageTitle(t("course.myCourses"));
    });

    const getClassroomName = (classID: string): string => {
      return classroomsData.value.find((item) => classID == item.classRoom._id)
        ?.classRoom.name as string;
    };

    const perPage = 7;

    const currentPage = ref<number>(1);

    const totalPages = computed(() =>
      Math.ceil(filteredCourses.value.length / perPage)
    );

    const pageRecords = computed(() => {
      const startIndex = perPage * (currentPage.value - 1) + 1;
      const endIndex = startIndex + perPage - 1;
      return filteredCourses.value.slice(startIndex - 1, endIndex);
    });

    return {
      t,
      courses,
      search,
      moment,
      filterCourses,
      filteredCourses,
      selectedClass,
      classroomsData,
      getClassroomCourses,
      getClassroomName,
      perPage,
      currentPage,
      totalPages,
      pageRecords,
      subjects,
      selectedSubject,
      loading,
      validateStat,
    };
  },
});
